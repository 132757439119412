<template>
    <div>
      <Row>
      <i-col span="24" class="text-right">
        <i-button type="error" class="m-r-5" size="small"  @click="cancelContractApproval" v-if="change.status===4">撤回审核申请</i-button>
      </i-col>
    </Row>
        <p class="workplatform-title m-b-5">合同概况</p>
        <Row>
          <i-col span="8">
           <span class="title">客户 </span> {{change.advertiserName}}
          </i-col>
            <i-col span="8">
           <span class="title">编码 </span>{{change.code}}
          </i-col>
           <i-col span="8">
           <span class="title">变更创建时间 </span>{{change.createTime}}
          </i-col>
        </Row>

        <Row>
          <i-col span="8">
           <span class="title">变更状态 </span> {{change.statusName}}
          </i-col>
          <i-col span="8">
           <span class="title">审批通过时间 </span>
            <span v-if="change.approvedTime!==''">{{change.approvedTime}}</span>
            <span v-else>-</span>
          </i-col>
        </Row>

        <p class="workplatform-title m-t-10 m-b-5">变更明细</p>
        <change-detail :detail="changeDetail"></change-detail>

        <!-- 审批记录 -->
        <history :type="8" :relateId="otherParams" ></history>
  </div>
</template>

<script>
import ChangeDetail from '@/components/contractChange/ChangeDetail'
import History from '@/components/approval/History'
import { getVersionDetail } from '@/api/scp/contractchange'
import { getChangeFields } from '@/utils/contractChange'
import { withdrawContractApproval } from '@/api/scp/contract'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  components: {
    History, ChangeDetail
  },
  data () {
    return {
      id: this.baseId,
      change: { },
      changeDetail: []
    }
  },
  methods: {
    getDetail () {
      getVersionDetail({ versionId: this.otherParams }).then(res => {
        this.change = res
        this.changeDetail = getChangeFields(res.changeDetail)
      })
    },
    cancelContractApproval () {
      const that = this
      let newCode = ''
      that.$Modal.confirm({
        title: '撤销审批确认',

        render: h => {
          return [h('p', '撤销审批，合同变更将变为草稿状态，确认撤销？', {

          }), h('Input', {
            props: {
              value: newCode,
              placeholder: '撤销备注'
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })]
        },
        onOk: () => {
          withdrawContractApproval({ versionId: that.id, remark: newCode }).then(res => {
            // this.$refs.contractDetail.getDetail()
            if (res && !res.errcode) {
              that.$Notice.success({ desc: '撤回审批申请成功！' })
              // window.location.reload()
              that.getDetail()
            }
          })
        }
      })
    }

  },
  created () {
    this.getDetail()
  },
  watch: {
    contractId (val) {
      this.id = this.baseId
      this.getDetail()
    }
  }

}
</script>
